<template>
  <div class="notice">
    <cms-header></cms-header>
<!--    <login :dialogFormVisible.sync="loginShow" style="width: 100px"></login>-->
    <!--面包屑-->
    <div class="width_width child_bg_1">
      <div class="width_w clearfix cms-container">
        <div class="now_add left clearfix">
          <router-link class="now_a left" to="/">首页</router-link>

          <img class="now_arrow left" src="../../assets/image/pc/jiantou.svg"/>
          <router-link to="/purchase">
            <a class="now_a left" href="#">招采公告</a>
          </router-link>
        </div>
      </div>
      <span class="child_name">采购详情</span>
    </div>
    <div class="cms-container notice-info">
<!--      <template v-if="notice.projectSource !== '20' && notice.projectSource !== '30'">-->
<!--        &lt;!&ndash;   登录后没有权限   &ndash;&gt;-->
<!--        <div v-if="(!isAllow) && userLoginFlag"-->
<!--             v-bind:style="{'position':'absolute','z-index': '200','margin-top': topHeight+'px','background': 'white','width': '100%','height':loginHeight,'opacity': '0.96'}">-->
<!--          <el-result icon="warning" title="您没有权限" subTitle="该项目为邀请项目，仅受邀单位可查看详细信息">-->
<!--          </el-result>-->
<!--        </div>-->
<!--        &lt;!&ndash;   没有登录,或者是采购中心的   &ndash;&gt;-->
<!--        <div v-if="!userLoginFlag && !isVc "-->
<!--             v-bind:style="{'position':'absolute','z-index': '200','margin-top': topHeight+'px','background': 'white','width': '100%','height':loginHeight,'opacity': '0.96'}">-->
<!--          <el-result icon="warning" title="您还未登录" subTitle="请登录后进行查看详细信息">-->
<!--            <template slot="extra">-->
<!--              <el-button type="primary" size="medium" v-on:click="login()">登录</el-button>-->
<!--            </template>-->
<!--          </el-result>-->
<!--        </div>-->
<!--      </template>-->
      <div class="notice-left left">
        <div class="news_child" id="child_content">
          <div class="child_title">
            <span class="child_span">{{ notice.title }}</span>
            <div class="notice_box">
                <span class="notice_time">
                    发布时间：{{this.$route.query.vc != null && this.$route.query.vc != undefined && this.$route.query.vc !="" && this.$route.query.vc !="0"?  notice.publishTime: notice.createTime }}
                    <a href="javascript:void(0)" style="color: #277eff; padding-left: 10px" v-if="showJoinNow" @click="joinNow(notice)">立即参与</a>
                </span>
            </div>
          </div>
<!--          <div class="child_content" v-html="notice.content" :style="{'height': showHeight,'overflow':'hidden'}">-->
          <div class="child_content" v-html="notice.content">
          </div>
        </div>
      </div>
    </div>
    <cms-foot class="left"></cms-foot>
  </div>
</template>

<script>
import CmsHeader from "../../components/commoon/component/cms-header";
import CmsFoot from "../new-home/cms-foot";
import Login from "../../components/commoon/component/login";
import {getUserInfo} from "../../api/commoon/user";

export default {
  name: "notice",
  components: {
    // Login,
    CmsFoot, CmsHeader
  },
  data() {
    return {
      loginShow: false,
      notice: {},
      buyer: {},
      buyerList: [],
      noticeTypeList: [],
      noticeList: [],
      configForm: {
        id: '203',
        // 客服热线
        hotline: '',
        // 客服名
        userName: '',
        // 客服人员电话
        phone: '',
        // 工作时间
        workTime: '',
        // 工作地址
        workAddress: ''
      },
      query: {},
      total: 0,
      currentPage: 0,
      pageSize: 16,
      userLoginFlag: null,
      userInfo: {},
      timer: null,
      loginHeight: "",
      topHeight: 400,
      isAllow: false,
      showHeight: "700px",
      isVc:false,
    }
  },
  computed: {
    showJoinNow() {
        const { bidOpenTime, projectSource, type, formType } = this.notice
        if (type !== 'BID_BULLETIN' && type !== 'CHANGE_BULLETIN') {
            return false
        }
        if(formType !== '10') {
          return false
        }
        if (bidOpenTime && projectSource && projectSource !== '15') {
            return new Date().getTime() < new Date(bidOpenTime).getTime()
        }
        return false
    }
  },
  methods: {
    joinNow(notice) {
        const { origin, host } = window.location
        const projectSource = notice.projectSource
        const isProd = host === 'www.holdcg.com'
        if (projectSource === '20') {
            window.location.href = (isProd ? 'https://www.holdcg.com':'http://dev6.ebidding.net.cn:18300') + '/ebidding/#/login'
        } else if (projectSource === '30') {
            window.location.href = (isProd ? 'http://hngt.zcjb.com.cn' : 'http://hngt.dev.ebidding.net.cn:16800') + '/ebidding/#/login'
        } else {
            window.location.href = origin + '/member/login/executeLogin.htm'
        }
    },
    getNoticeList: function (query) {
      let that = this;
      this.$http.post(this.base + "/bulletin/getAll.do", {
        page: {
          current: that.currentPage,
          size: that.pageSize
        },
        cmsBulletinDTO: query
      }).then(function (data) {
        that.noticeList = data.data.result.records;
        that.total = data.data.result.total;
      }).catch(function (error) {
        console.log(error);
      })
    },
    selectPromotionList: function (modelId, entityName, entityListName) {
      let that = this;
      that.$http.get(that.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[entityName] = data.data.result;
        let categoryId = data.data.result.id;
        that.$http.post(that.base + "/promotion/page.do", {
          page: {
            size: 6,
            current: 0
          },
          cmsPromotionDTO: {
            categoryId: categoryId
          }
        }).then(function (data) {
          let data1 = data.data.result.records;
          for (let i = 0; i < data1.length; i++) {
            if (data1[i].imagesUrl != null && data1[i].imagesUrl != "") {
              data1[i].imagesUrl = JSON.parse(data1[i].imagesUrl);
            }
          }
          that[entityListName] = data1;
        }).catch(function (error) {
          console.log(error)
        })
      }).catch(function (error) {
        console.log(error)
      })
    },
    getConfigs: function (id) {
      let that = this;
      this.$http.get(that.base + "/content/getCall.do", {
        params: {
          id: id
        }
      }).then(function (res) {
        let data = res.data.result;
        if (data) {
          that.configForm = data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleCurrentChange: function (val) {
      this.currentPage = val;
      this.getNoticeList(this.query);
    },
    getParameter: function (paraName) {
      let url = document.location.toString();
      if (url.lastIndexOf('#') == (url.length - 1)) {
        url = url.substring(0, url.lastIndexOf('#'));
      }
      let arrObj = url.split("?");

      if (arrObj.length > 1) {
        let arrPara = arrObj[1].split("&");
        let arr;

        for (let i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    get: function (id, type, contentId) {
      let that = this;
      // /
      let vc = that.$route.query.vc;
      let url = "";
      if (vc != null && vc != undefined && vc != "" && vc !="0") {
        url = "/vcBggBulletinMain/getVcBulletinContent.do";
      } else {
        url = "/bulletin/get.do";
      }
      that.$http.get(that.base + url, {
        params: {
          id: id,
          type: type,
          contentId : contentId
        }
      }).then(function (data) {
        that.notice = data.data.result;
        that.$nextTick(() => {
          //渲染以后获取高度
          //元素高度
          let childContentHeight = document.getElementById("child_content").offsetHeight;
          if (childContentHeight <= 700) {
            //头部高度
            that.topHeight = 300;
          }
          //设置遮罩的高度
          that.loginHeight = (childContentHeight - that.topHeight) + "px";
        })
      }).catch(function (error) {
        console.log(error)
      })
    },
    //判断用户是否登入
    checkUser: function (uuid) {
      let that = this;
      getUserInfo(that.member).then(function (res) {
        that.userInfo = res.data.data;
        if (that.userInfo.isLogin =='1') {
          that["userLoginFlag"] = true;
        } else {
          that["userLoginFlag"] = false;
        }
      }).catch(function (err) {
        console.log(err);
      });

    },
    //从cookie获取值
    getCookie: function (name) {
      let that = this;
      let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if (arr = document.cookie.match(reg)) {
        return (arr[2]);
      } else
        return null;
    },
    login: function () {
      this.loginShow = true;
    },
    isAllowCheck() {
      let that = this;
      let formType = this.$route.query.formType;
      let contentId = this.$route.query.contentId;
      //检查用户是否登录
      let uuid = this.getCookie("uuid");
      if (that.$route.query.vc != null && that.$route.query.vc !=undefined && that.$route.query.vc !=""  && that.$route.query.vc !="0" ){
        //采购中心，不做限制
        that.isAllow = true;
        if (that.isAllow) {
          that.showHeight = 'auto';
        }
        that.isVc =true;
      }else {
        getUserInfo(that.member).then(function (res) {
          that.userInfo = res.data.data;
          if (that.userInfo.isLogin =='1') {
            that["userLoginFlag"] = true;
            getUserInfo(that.member).then(function (res) {
              that.userInfo = res.data.data;
              if ((formType == '10' || formType == "2010" || formType == "4010" || formType == "9010") && that.userLoginFlag) {
                //公开的项目,必须登录后才能查看
                that.isAllow = true;
                if (that.isAllow) {
                  that.showHeight = 'auto';
                }
              } else if ((formType == '20' || formType == "2020" || formType == "4020" || formType == "9020") && that.userLoginFlag) {
                //是邀请项目，并且已经登录
                that.$http.get(that.base + "/bulletin/checkUserAllow.do", {
                  params: {
                    companyId: that.userInfo.companyId,
                    contentId: that.$route.query.contentId,
                    type: that.$route.query.type,
                  }
                }).then(function (resData) {
                  if (resData.data.result) {
                    //有权限
                    that.isAllow = true;
                    that.showHeight = 'auto';
                  }
                }).catch(function (err2) {
                  console.log(err2);
                })
              }

            }).catch(function (err) {
              console.log(err);
            });
          }else {
            that["userLoginFlag"] = false;
          }
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
  }, created() {
    /*初始化卡片*/
    this.getConfigs(203);
    let id = this.getParameter("contentId");
    let contentId = this.getParameter("id");
    let type = this.getParameter("type");
    this.get(id, type, contentId);
    this.checkUser(this.getCookie("uuid"));
    this.isAllowCheck();
    window.addEventListener('resize', () => {
      this.isAllowCheck();
      //屏幕大小变化的方法
    }, true);
    document.title = this.title;
  }, watch: {
    loginShow: {
      deep: true,    // 这句重要
      handler(val) {
        this.isAllowCheck();
      }
    }
  }
}
</script>

<style scoped lang="css" src="../../assets/commoon/css/common.css"></style>
<style scoped>
.notice-left {
  width: 100% !important;
}
</style>
